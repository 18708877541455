/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import hembhatnagar from "../assets/images/hembhatnagar.png";
import founder1 from "../assets/images/m2.jpg";
import founder4 from "../assets/images/m4.jpg";
import founder2 from "../assets/images/jdm.png";
import founder3 from "../assets/images/booklist.jpg";
import author1 from "../assets/images/author1 (1).jpeg"
import author2 from "../assets/images/self 2.jpg";
import author3 from "../assets/images/garav bhatnagar2.jpg";
import author4 from "../assets/images/author1 (4).jpeg";
import author5 from "../assets/images/asha pic_2.jpg";
import author6 from "../assets/images/deepti prasad-2.jpg"
import { motion } from "framer-motion";


function AboutMain() {
    return (
        <div className='mb-8'>

            <div class="max-w-7xl mx-auto justify-center md:py-20 lg:py-20 xl:py-20 py-10 md:px-0 lg:px-0 xl:px-0 px-2">
                <h1 class="mb-4 text-3xl font-bold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-5xl ">Founder</h1>

                <motion.div
                    className="flex flex-col lg:flex-row justify-between gap-3">
                    <motion.div
                        initial={{
                            x: -300,
                            opacity: 0,
                            scale: 0.8,
                        }}
                        animate={{
                            x: 0,
                            opacity: 1,
                            scale: 1,
                        }}
                        transition={{
                            duration: 2,
                        }}
                        className="w-full lg:w-6/12 flex flex-col justify-center">
                        <h1 className="text-3xl lg:text-3xl font-semibold leading-9 text-gray-800  pb-4">Dr. Hem Bhatnagar <br /> <span className='text-xl font-semibold'>(28 October 1929 - 25 January, 2022)</span></h1>
                        <p className="font-normal md:text-lg lg:text-lg text-base leading-6 text-gray-600 ">
                            In 2012 Hem Aunty was conferred Lifetime Achievement Award by Association of Writers and Illustrators For Children. An article written by Madhulika, published in their Journal on this occasion may help us get a glimpse of her and the vast area of her work. <a
                                href='/uploads/about_the_founder.pdf'
                                target="_blank" rel="noopener noreferrer"
                                className='underline'>you can download it here .</a>
                            <br />
                            <br />
                            She completed 90 years on October 28, 2019. We asked her family and friends if they wished to say something in tribute or regarding their association with her. Madhulika and Gaurav collated them as a book, which was presented to her on her birthday. It was meant to be a book of wishes, but it ended up presenting a very multidimensional view of her as a person. It is also inspirational, in parts, and has some interesting historical photographs too. <a
                                href='/uploads/mom_book_final_small.pdf'
                                target="_blank" rel="noopener noreferrer"
                                className='underline'>Click on this sentence to download the book.</a>
                            <br />
                            <br />
                            Much loved -- Mummy/Daadi/Hem aunty/Hemji/Mrs. Bhatnagar decided to leave us on January 25, 2022 at the ripe age of 92 years. The video recording of the remembrance meeting is <a href="https://www.youtube.com/watch?v=kPUqziMCxik" target="_blank" rel="noopener noreferrer"
                                className='underline'>here</a>. The first 40 minutes or so is Sundar Kand, from an LP which was played incessantly in JDM. The program follows. Part I of the program, the planned part, took about 2 hours. After that there was Part II, which was less planned but perhaps most insightful.</p>
                    </motion.div>


                    <motion.div
                        initial={{
                            x: 300,
                            opacity: 0,
                            scale: 0.8,
                        }}
                        animate={{
                            x: 0,
                            opacity: 1,
                            scale: 1,
                        }}
                        transition={{
                            duration: 2,
                        }}
                        class="w-full lg:w-5/12">
                        <img class="w-full h-full rounded-lg" src={hembhatnagar} alt="Founder" />
                    </motion.div>
                </motion.div>


            </div>


            <div class="bg-white dark:bg-gray-800 h-auto py-6 sm:py-8 lg:py-12">
                <div class="mx-auto max-w-7xl px-4 md:px-12 lg:px-12 xl:px-12">


                    <div class="grid grid-cols-2 gap-4 sm:grid-cols-3 md:gap-4 xl:gap-6">

                        <motion.a
                            initial={{
                                x: -400,
                                opacity: 0,
                                scale: 0.8,
                            }}
                            animate={{
                                x: 0,
                                opacity: 1,
                                scale: 1,
                            }}
                            transition={{
                                duration: 1,
                            }}


                            href="/"
                            class="group relative flex h-56 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80">
                            <img src={founder1} loading="lazy" alt="Founder-photo-1"
                                className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />

                            <div
                                class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                            </div>


                        </motion.a>

                        <motion.a
                            initial={{
                                x: 400,
                                opacity: 0,
                                scale: 0.8,
                            }}
                            animate={{
                                x: 0,
                                opacity: 1,
                                scale: 1,
                            }}
                            transition={{
                                duration: 1,
                            }}
                            href="/"
                            class="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 md:h-80">
                            <img src={founder2} loading="lazy" alt="Founder-photo-2" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />

                            <div
                                class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                            </div>


                        </motion.a>

                        <motion.a
                            initial={{
                                x: -400,
                                opacity: 0,
                                scale: 0.8,
                            }}
                            animate={{
                                x: 0,
                                opacity: 1,
                                scale: 1,
                            }}
                            transition={{
                                duration: 1,
                            }}
                            href="/"
                            class="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 md:h-80">
                            <img src={founder3} loading="lazy" alt="Founder-photo-3" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />

                            <div
                                class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                            </div>


                        </motion.a>

                        <motion.a
                            initial={{
                                x: 400,
                                opacity: 0,
                                scale: 0.8,
                            }}
                            animate={{
                                x: 0,
                                opacity: 1,
                                scale: 1,
                            }}
                            transition={{
                                duration: 1,
                            }}
                            href="/"
                            class="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80">
                            <img src={founder4} loading="lazy" alt="Founder-photo-4" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />

                            <div
                                class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                            </div>

                        </motion.a>

                    </div>
                </div>
            </div>

            <div class="max-w-7xl mx-auto justify-center md:py-10 lg:py-10 xl:py-10 py-10 md:px-0 lg:px-0 xl:px-0 px-2 ">
                <h1 class="mb-4 text-3xl font-bold leading-none tracking-tight text-gray-900 md:text-4xl lg:text-4xl ">Core Team</h1>

                <div class="flex flex-col lg:flex-row justify-between gap-3">

                    <div class="w-full lg:w-5/12">
                        <img class="w-full h-full rounded-lg hover:scale-105 hover:transition mt-4" src={author1} alt="Founder" />
                    </div>

                    <div class="w-full lg:w-6/12 flex flex-col justify-center">
                        <h1 class="text-3xl lg:text-4xl font-semibold leading-9 text-gray-800  pb-4 ">Amit Bhatnagar <br /> <span className='text-xl font-semibold underline underline-offset-4'>'Saumya'
                            Educationist and Author </span></h1>
                        <p class="font-normal md:text-lg lg:text-lg text-base leading-6 text-gray-600  bg-gray-200/50 p-3">Amit is working in the education of tribals and creating awareness amongst them.
                            <br />
                            <br />
                            After completing three years of his studies at Delhi School of Architecture and Town Planning, in 1981 Amit left for western Madhya Pradesh to do social work among tribals there.

                            After working for some years at Tilonia, Zhabua and Atta, he along with his wife Jayshree have been running a school named 'Adharshila Shikshan Kendra' estabilished by 'Veer Khajya Nayak Manav Vikas Pratishthan' at village Sakad in Badwani district.
                            <br />
                            <br />
                            The goal of this education is that children become self reliant and capable in every field and to fulfill their dreams.

                            Besides writing Amit likes to paint. He plays violin also.</p>
                    </div>
                </div>


            </div>

            <div class="max-w-7xl mx-auto justify-center md:py-10 lg:py-10 xl:py-10 py-10 md:px-0 lg:px-0 xl:px-0 px-2 ">


                <div class="flex flex-col lg:flex-row justify-between gap-3 ">
                    <div class="w-full lg:w-6/12 flex flex-col justify-center ">
                        <h1 class="text-3xl lg:text-4xl font-semibold leading-9 text-gray-800  pb-4">Madhulika Agarwal
                            <br /> <span className='text-xl font-semibold underline underline-offset-4'>Author </span></h1>
                        <p class="font-normal md:text-lg lg:text-lg text-base leading-6 text-gray-600  bg-gradient-to-r from-gray-100 via-yellow-50 to-orange-100 md:p-3 lg:p-3 xl:p-3 p-2">Madhulika Agarwal's stories for children have been published by Samskar, NCERT, CBT, Macmillan and AWIC in their collections. She also has eight independently published books to her credit. She was awarded Hindi Academy's 'Bal Aur Kishore Sahitya Samman' in 2007-08 for her collection of Short stories. Children's Book Trust awarded her manuscript in 2020. Besides writing she has two decades of experience in book designing, editing and publishing under Dr. Hem Bhatnagar's guidance in her publishing unit. She regularly takes online creative writing classes for young budding writers and publishes the stories developed in these workshops.
                            <br />

                            .</p>
                    </div>
                    <div class="w-full lg:w-5/12">
                        <img class="w-full h-full rounded-lg hover:scale-105 hover:transition mt-4" src={author2} alt="Founder" />
                    </div>
                </div>


            </div>


            <div class="max-w-7xl mx-auto justify-center md:py-10 lg:py-10 xl:py-10 py-10 md:px-0 lg:px-0 xl:px-0 px-2 ">


                <div class="flex flex-col lg:flex-row justify-between gap-3">

                    <div class="w-full lg:w-5/12">
                        <img class="w-full h-full rounded-lg hover:scale-105 hover:transition mt-4" src={author3} alt="Founder" />
                    </div>

                    <div class="w-full lg:w-6/12 flex flex-col justify-center">
                        <h1 class="text-3xl lg:text-4xl font-semibold leading-9 text-gray-800  pb-4 ">Gaurav Bhatnagar
                            <br /> <span className='text-xl font-semibold underline underline-offset-4'>Mathematician  </span></h1>
                        <p class="font-normal md:text-lg lg:text-lg text-base leading-6 text-gray-600  bg-gray-200/50 p-3">Gaurav Bhatnagar obtained his Ph.D. in Mathematics from The Ohio State University in 1995.
                            <br />
                            <br />
                            After his Ph.D., he spent one year each at Ohio State and the Indian Statistical Institute, Delhi.

                            Subsequently, he joined the educational technology industry, where he has been able to make a significant contribution to the teaching and learning processes of Indian schools. Since September 2015, he has been a post-doctoral researcher in various institutions, including ISI, Delhi; the Faculty of Mathematics at the University of Vienna; and, the School of Physical Sciences (SPS), JNU. Currently he is Visiting Associate Professor, Ashoka University.
                        </p>
                    </div>
                </div>
            </div>


            <div class="max-w-7xl mx-auto justify-center md:py-10 lg:py-10 xl:py-10 py-10 md:px-0 lg:px-0 xl:px-0 px-2 ">
                <h1 class="mb-4 text-3xl font-bold leading-none tracking-tight text-gray-900 md:text-4xl lg:text-4xl ">Pillars Of Support</h1>
                <div class="flex flex-col lg:flex-row justify-between gap-3">
                    <div class="w-full lg:w-6/12 flex flex-col justify-center">
                        <h1 class="text-3xl lg:text-4xl font-semibold leading-9 text-gray-800  pb-4 ">Deepti Prasad<br /> <span className='text-xl font-semibold underline underline-offset-4'>Retired Lecturer</span></h1>
                        <p class="font-normal md:text-lg lg:text-lg text-base leading-6 text-gray-600  bg-gray-200/50 p-3">Deepti first upgraded her knowledge by studying Dietetics. She also added degrees in Education (BEd and MEd) as
                            well as Masters in English. Thus equipped, Deepti taught for over thirty
                            years in convent and public schools as a lecturer in English.
                            <br />
                            <br />
                            During the pandemic years, she took online classes for spoken and written English at Gurukool, a school for underprivileged children. She also
                            conducted a workshop on finer aspects of teaching English at the British
                            Council.
                            Her passion for teaching led her and her husband to join a course on
                            'Teach India', an initiative of The Times of India.
                            Deepti is now retired, but passionate about knitting, organic gardening
                            and making homemade pickles. She is known in the family to have written so many poems about all elderly family members which touched
                            their hearts, besides poems on general situations in life. <a href="dp.pdf" target="_blank" rel="noopener noreferrer" className='text-blue-600 font-normal italic'>Read more.</a>
                        </p>
                    </div>
                    <div class="w-full lg:w-5/12">
                        <img class="w-full h-full rounded-lg hover:scale-105 hover:transition mt-4" src={author6} alt="Founder" />
                    </div>
                </div>
            </div>
            <div class="max-w-7xl mx-auto justify-center md:py-10 lg:py-10 xl:py-10 py-10 md:px-0 lg:px-0 xl:px-0 px-2 ">

                <div class="flex flex-col lg:flex-row justify-between gap-3">
                    <div class="w-full lg:w-6/12 flex flex-col justify-center">
                        <h1 class="text-3xl lg:text-4xl font-semibold leading-9 text-gray-800  pb-4">Suvidha Mistry
                            <br /> <span className='text-xl font-semibold underline underline-offset-4'>Illustrator</span></h1>
                        <p class="font-normal md:text-lg lg:text-lg text-base leading-6 text-gray-600 bg-gradient-to-r from-gray-100 via-yellow-50 to-orange-100 md:p-3 lg:p-3 xl:p-3 p-2">Suvidha Mistry has illustrated for various publishing houses in India. Her training in graphic art led to various jobs with multimedia and advertising agencies. But her turning point was when she illustrated her first children book. Her book cover for 'The Naughty Bear' written by Paro Anand, publishesd by Scholastic India has been awarded by Publishing Next in 2019. She's been honoured by the Association of Writers and Illustrators for Children and has participated in the Nambook010, International Children's Book Festival, Korea 2010.
                            <br />
                            Her works have been exhibited in Korea and Bratislava. Suvidha is an avid traveller and believes that there are many paths that lead to one's dreams and wishes to explore them all. Long drives are her passion and she enjoys her journeys immensely and comes back laden with new ideas, dazzling memories and bag full of textured stones and twigs. When she's not introducing children to the world of art and crafts, she is busy tending to her terrace and also community gardens of her society which she designed and got it developed.
                            .</p>
                    </div>
                    <div class="w-full lg:w-5/12">
                        <img class="w-full h-full rounded-lg hover:scale-105 hover:transition mt-4" src={author4} alt="Founder" />
                    </div>
                </div>


            </div>

            <div class="max-w-7xl mx-auto justify-center md:py-10 lg:py-10 xl:py-10 py-10 md:px-0 lg:px-0 xl:px-0 px-2 ">
                <div class="flex flex-col lg:flex-row justify-between gap-3">

                    <div class="w-full lg:w-5/12">
                        <img class="w-full h-full rounded-lg hover:scale-105 hover:transition mt-4" src={author5} alt="Founder" />
                    </div>

                    <div class="w-full lg:w-6/12 flex flex-col justify-center">
                        <h1 class="text-3xl lg:text-4xl font-semibold leading-9 text-gray-800  pb-4 ">Asha Agarwal<br /> <span className='text-xl font-semibold underline underline-offset-4'>Illustrator</span></h1>
                        <p class="font-normal md:text-lg lg:text-lg text-base leading-6 text-gray-600  bg-gray-200/50 p-3">Since childhood she was interested in drawing and colouring.
                            She did post graduation in fine arts along with graduation in commerce. She received specialised training in designing.
                            <br />
                            <br />
                            Nowadays she teaches painting to children and women. 'Mehek ka mogra' was the first book illustrated by her.

                            Currently, she is illustrating many books. As the secretary of 'Akhil Bhartiya Ulta danga Marwari Samiti' she is actively involved in social work.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AboutMain