import React from 'react'

const Delivery = () => {
  return (
    <div className='w-full p-10 text-justify font-sans subpixel-antialiased bg-slate-100 '>

      <section className="mb-6 ">
        <h2 className="text-2xl font-semibold mb-2">SHIPPING AND DELIVERY
        </h2>
        <p className="text-gray-700">
          Shipping costs are calculated as 25% (20%) of the order value. The minimum order value on the e-store is ₹200. Free shipping within India for orders above ₹2000.
        </p>
        <p className="text-gray-700">
          To return your product, you should mail your product to: <strong>B 35 Swasthya Vihar, First Floor, Delhi 92.</strong>
        </p>
        <p className="text-gray-700">
          You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable.
        </p>
      </section>

      <section className="mb-6 -mt-3">
        <h2 className="text-2xl font-semibold mb-2">General Conditions</h2>
        <p className="text-gray-700">
          We reserve the right to refuse service to anyone for any reason at any time. You understand that your content (not including credit card information) may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.
        </p>
        <p className="text-gray-700">
          <strong>You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of the Service, use of the Service,</strong> or access to the Service or any contact on the website through which the service is provided, without express written permission by us.
        </p>
        <p className="text-gray-700">
          You promise to only use the services in a manner that complies with all laws that apply to you. If your use of the services is prohibited by applicable laws, then you aren’t authorized to use the services.
        </p>
        <p className="text-gray-700">
          You are also <strong>responsible for maintaining the confidentiality of your account password</strong> and for the security of your account. You will notify Hema Aunty Foundation<strong>(Hem Aunty Publications)</strong> immediately of any actual or suspected loss, theft, or unauthorized use of your account or account password. Any violation of the Terms and Conditions by anyone using the services under your account (or otherwise under your authority or permission) may be deemed a violation by you, irrespective of whether the violation is with or without your consent.
        </p>
      </section>

      <section className="mb-6 -mt-3">
        <h2 className="text-2xl font-semibold mb-2">Accuracy, Completeness, and Timeliness of Information</h2>
        <p className="text-gray-700">
          We are not responsible if information made available on this site is not accurate, complete, or current. Any reliance on the material on this site is at your own risk. This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.
        </p>
      </section>

      <section className="mb-6 -mt-3">
        <h2 className="text-2xl font-semibold mb-2">Use of Content + Prohibited Uses</h2>
        <p className="text-gray-700">You agree, undertake, and confirm that your use of the services and content on the website shall be strictly governed by the following binding principles:</p>
        <ul className="list-disc list-inside ml-4 text-gray-700">
          <li>Belongs to another person and to which you do not have any right.</li>
          <li>Is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libelous, invasive of another’s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to “indecent representation of women” within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986.</li>
          <li>Is misleading in any way.</li>
          <li>Involves the transmission of “junk mail,” “chain letters,” or unsolicited mass mailing or “spamming.”</li>
          <li>Promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory, or libelous.</li>
          <li>Infringes upon or violates any third party’s rights [including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person’s name, email address, physical address, or phone number) or rights of publicity].</li>
          <li>Promotes an illegal or unauthorized copy of another person’s copyrighted work (see “Copyright Complaint” below for instructions on how to file a complaint about a posted copyrighted work), such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files.</li>
          <li>Contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible page).</li>
          <li>Provides material that exploits people in a sexual, violent, or otherwise inappropriate manner or solicits personal information from anyone.</li>
          <li>Provides instructional information about illegal activities such as making or buying illegal weapons, violating someone’s privacy, or providing or creating computer viruses.</li>
          <li>Contains video, photographs, or images of another person (with a minor or an adult).</li>
          <li>Tries to gain unauthorized access or exceeds the scope of authorized access to the Website or to profiles, blogs, communities, account information, bulletins, friend requests, or other areas of the Website or solicits passwords or personal identifying information for commercial or unlawful purposes from other users.</li>
          <li>Engages in commercial activities and/or sales without our prior written consent such as contests, sweepstakes, barter, advertising, and pyramid schemes.</li>
          <li>Solicits gambling or engages in any gambling activity which we, in our sole discretion, believe is or could be construed as being illegal.</li>
          <li>Interferes with another user’s use and enjoyment of the Website or any other individual’s user and enjoyment of similar services.</li>
          <li>Refers to any website or URL that, in our sole discretion, contains material that is inappropriate for the Website or any other website, contains content that would be prohibited or violates the letter or spirit of these Terms and Conditions.</li>
          <li>Harm minors in any way.</li>
          <li>Infringes any patent, trademark, copyright, or other proprietary rights.</li>
          <li>Deceives or misleads the addressee/users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature.</li>
          <li>Impersonates another person.</li>
          <li>Contains software viruses or any other computer code, files or programs designed to interrupt, destroy, or limit the functionality of any computer resource; or contains any Trojan horses, worms, time bombs, cancelbots, easter eggs, or other computer programming routines that may damage, detrimentally interfere with, diminish the value of, surreptitiously intercept, or expropriate any system, data, or personal information.</li>
          <li>Threatens the unity, integrity, defense, security, or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offense or prevents investigation of any offense or is insulting any other nation.</li>
          <li>Shall, directly or indirectly, offer, attempt to offer, trade, or attempt to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation, or guideline for the time being in force.</li>
          <li>Shall create liability for us or cause us to lose (in whole or in part) the services of our Internet service provider or other suppliers.</li>
        </ul>
      </section>
    </div>
  )
}

export default Delivery