import React from 'react'
import Dashboard from '../components/Dashboard'

function DashboardPage() {
    return (
        <div>
            <Dashboard />
        </div>
    )
}

export default DashboardPage