import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import config from '../config/config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import payment from "../assets/images/scannerfile.jpg"
import RenderRazorpay from '../common/RenderRazorPay'
import ReCAPTCHAComponent from '../common/googleCaptcha';

const Checkout = () => {
    const { API_BASE_URL ,RAZOR_PAY_API_KEY,RAZOR_PAY_API_SECRET} = config;

    const location = useLocation();

    const cartItems = location.state ? location.state.cartItems : [];
    const total = location.state ? location.state.total : 0;

    const [displayRazorpay, setDisplayRazorpay] = useState(false);
    
    const [ordeid, setorderID] = useState("");
    const [preFillFormData ,setPreFillFormData] = useState({
        name:'',
        mobile:'',
        email:''
    });
    const [hemOrderID ,setHemOrderId] = useState(null);



    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        pinCode: '',
        address: '',
        city: '',
        state: '',
    });
    // const [form ,setform]=useState(formData)
    // const formDatas = formData;
    // console.log("formdata in array",formDatas);

    const [paymentProofImage, setPaymentProofImage] = useState(null);
    const [isCaptchaValid, setIsCaptchaValid] = useState(false); // Captcha state
    const [loading ,setLoading] = useState(false)

    const handleRecaptcha = (value) => {
        setIsCaptchaValid(!!value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setPreFillFormData({
            name:formData.name,
            mobile:formData.mobile,
            email:formData.email
        });
        setLoading(true);
        setIsCaptchaValid(false);

        const storedCart = localStorage.getItem('cart');
        const cartItems = storedCart ? JSON.parse(storedCart) : [];

        const checkoutPayload = {
            total,
            orderDate: new Date().toISOString(),
            paymentDate: new Date().toISOString(),
            paymentProof: paymentProofImage,
            ...formData,
            _lstUM_OrderItem: cartItems
                .filter(item => item.quantity > 0)
                .map(item => ({
                    bookId: item.id,
                    bookName: item.name,
                    bookQuantity: item.quantity,
                    perUnitBookPrice: item.price,
                    totalBookPrice: item.price * item.quantity,
                    addDate: new Date().toISOString(),
                    updateDate: new Date().toISOString(),
                })),
            _lstUM_OrderStatus: [
                {
                    id: 0,
                    orderId: 0,
                    statusID: 0,
                    statusName: 'string',
                    updateDate: new Date().toISOString(),
                    updatedBy: 0,
                    isActive: 0,
                },
            ],
        };

        try {
            const response = await fetch(`${API_BASE_URL}/Home/Checkoutorder`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(checkoutPayload),
            });

            if (response.ok) {
                const data = await response.text();
                const RazorpayorderIds = JSON.parse(data).gatewayOrderID
                console.log("data",JSON.parse(data).gatewayOrderID);
                const hemOrderId = JSON.parse(data).orderId;
                setHemOrderId(hemOrderId)
                setDisplayRazorpay(true);
                setorderID(RazorpayorderIds);
                setLoading(true);
                setIsCaptchaValid(false);


               

                toast.success(
                    <div className=''>
                        <p>Please save the order for tracking and future reference.</p>
                        <hr />
                        <p className='font-bold'> Order Id : &nbsp; {hemOrderId}</p>
                        <button className='mt-1 bg-red-500 rounded-md text-white text-xs px-2 py-1' onClick={() => toast.dismiss()}>Close</button>
                    </div>,
                    { autoClose: false }
                );
                localStorage.removeItem('cart');

                setFormData({
                    name: '',
                    mobile: '',
                    email: '',
                    pinCode: '',
                    address: '',
                    city: '',
                    state: '',
                });

                setPaymentProofImage(null);

                // Send email notification
                const emailFormData = new FormData();
                emailFormData.append('ToEmail', 'sales@mail.edumitram.co.in');
                emailFormData.append('Subject', 'New Order Notification');
                const emailBodyHTML = `
                <p style="font-weight: bold;">A new order has been placed with the following details:</p>
                <br>
                <p><span style="font-weight: bold;">Tracking Order ID:</span> ${JSON.parse(data).orderId}</p>
                <p><span style="font-weight: bold;">Total Price:</span> Rs. ${total}</p>
                <p><span style="font-weight: bold;">Name:</span> ${formData.name}</p>
                <p><span style="font-weight: bold;">Mobile:</span> ${formData.mobile}</p>
                <p><span style="font-weight: bold;">Email:</span> ${formData.email}</p>
                <p><span style="font-weight: bold;">Pincode:</span> ${formData.pinCode}</p>
                <p><span style="font-weight: bold;">Address:</span> ${formData.address}</p>
                <p><span style="font-weight: bold;">City:</span> ${formData.city}</p>
                <p><span style="font-weight: bold;">State:</span> ${formData.state}</p>`;

                console.log("emailFormData",emailFormData)


                emailFormData.append('Body', emailBodyHTML);

                emailFormData.append('EmailCode', '1');
                emailFormData.append('Body', `A new order has been placed with the following details:\n\nOrder ID: ${data}\nTotal Price: Rs. ${total}\nName: ${formData.name}\nMobile: ${formData.mobile}\nEmail: ${formData.email}\nPincode: ${formData.pinCode}\nAddress: ${formData.address}\nCity: ${formData.city}\nState: ${formData.state}`);

                const emailResponse = await fetch('https://edmcorpservices.edumitram.co.in/api/Email/SendHemPub', {
                    method: 'POST',
                    body: emailFormData,
                });

                if (!emailResponse.ok) {
                    throw new Error('Failed to send email notification');
                }

            } else {
                toast.error('Error while saving the order. Please try again.');
                setLoading(true);
                setIsCaptchaValid(false)
            }
        } catch (error) {
            toast.error('An unexpected error occurred. Please try again later.');
            setLoading(true);
            setIsCaptchaValid(false)
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleImageChange = (e) => {

        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPaymentProofImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (

        <section class="bg-white py-20 lg:py-[120px] overflow-hidden relative max-w-7xl mx-auto px-5">
            <div class="container z-10">
                <div class="flex flex-wrap lg:justify-between -mx-4">
                    <div class="w-full lg:w-1/2 xl:w-6/12 px-4">
                        <div class="max-w-[570px] mb-12 lg:mb-0">
                            <p class="block mb-4 text-xl font-semibold underline">
                                Checkout
                            </p>


                            <p class="text-lg text-white leading-relaxed mb-4 bg-blue-600 px-4 py-1 w-fit rounded-md tracking-[1px]">
                                Total Price:{` `}
                                <span className='font-medium'>Rs.{total}</span>
                            </p>

                            <p class="text-lg font-normal text-slate-800 leading-relaxed mb-3">
                                Kinldy fill out the perform to proceed!
                            </p>

                            <p class="text-lg font-normal text-slate-800 leading-relaxed mb-3">
                                For assistance, call our support at: <span className='font-semibold'>+91 9958410093</span>
                            </p>
                            {/* <div className='space-y-4'>
                                <p className='bg-blue-700 p-2 rounded-md text-white w-fit'>Scan the QR to pay.</p>
                                <img
                                    className='max-w-sm'
                                    src={payment} alt="payment-method" />
                            </div> */}
                        </div>
                    </div>
                    <div class="w-full lg:w-1/2 xl:w-5/12 px-4">

                        <h1 className='font-bold text-xl py-2 text-gray-800'>Deliver to Address</h1>
                        <div class="bg-gray-100/80 border relative rounded-lg p-8 sm:p-12 shadow-lg">

                            {/*  <form> */}
                            <form onSubmit={handleSubmit} >
                                <div class="mb-6">
                                    <label className='items-start w-full text-gray-700 font-medium'>
                                        Name
                                        <input
                                            type="text"
                                            placeholder="Mention your name"
                                            className="w-full rounded py-2 placeholder:text-md placeholder:font-normal placeholder:text-sm placeholder:text-gray-400 px-[14px] text-body-color text-base border border-[f0f0f0] outline-none focus-visible:shadow-none focus:border-primary items-start" name="name" value={formData.name} onChange={handleChange} required />
                                    </label>
                                </div>

                                {cartItems.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <input type="hidden" name={`cartItems[${index}].bookId`} value={item.bookId} />
                                        <input type="hidden" name={`cartItems[${index}].bookName`} value={item.bookName} />
                                        <input type="hidden" name={`cartItems[${index}].bookQuantity`} value={item.bookQuantity} />
                                        <input type="hidden" name={`cartItems[${index}].perUnitBookPrice`} value={item.perUnitBookPrice} />
                                        <input type="hidden" name={`cartItems[${index}].totalBookPrice`} value={item.totalBookPrice} />
                                        <input type="hidden" name={`cartItems[${index}].addDate`} value={item.addDate} />
                                        <input type="hidden" name={`cartItems[${index}].updateDate`} value={item.updateDate} />
                                    </React.Fragment>
                                ))}

                                <div class="mb-6">
                                    <label className='items-start w-full text-gray-700 font-medium'>
                                        Mobile
                                        <input
                                            placeholder='+91 75*******'
                                            className="w-full rounded py-2 placeholder:text-md placeholder:font-normal placeholder:text-sm placeholder:text-gray-400 px-[14px] text-body-color text-base border border-[f0f0f0] outline-none focus-visible:shadow-none focus:border-primary items-start" type="text" name="mobile" value={formData.mobile} onChange={handleChange} required />
                                    </label>
                                </div>

                                <div class="mb-6">
                                    <label className='items-start w-full text-gray-700 font-medium'>
                                        Email
                                        <input
                                            placeholder='name@gmail.com'
                                            className="w-full rounded py-2 placeholder:text-md placeholder:font-normal placeholder:text-sm placeholder:text-gray-400 px-[14px] text-body-color text-base border border-[f0f0f0] outline-none focus-visible:shadow-none focus:border-primary items-start" type="email" name="email" value={formData.email} onChange={handleChange} required />
                                    </label>
                                </div>

                                {/* <div className="mb-6">
                                    <label className="items-start w-full text-gray-700 font-medium">
                                        Payment Proof Image:
                                        <input
                                            className="w-full rounded py-3 px-[14px] text-body-color text-base border border-[f0f0f0] outline-none focus-visible:shadow-none focus:border-primary items-start"
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                        />
                                    </label>
                                </div> */}

                                <div class="mb-6">
                                    <label className='items-start w-full text-gray-700 font-medium'>
                                        Pincode
                                        <input
                                            placeholder='Mention your Pincode'
                                            className="w-full rounded py-2 placeholder:text-md placeholder:font-normal placeholder:text-sm placeholder:text-gray-400 px-[14px] text-body-color text-base border border-[f0f0f0] outline-none focus-visible:shadow-none focus:border-primary items-start" type="text" name="pinCode" value={formData.pinCode} onChange={handleChange} required />
                                    </label>
                                </div>

                                <div class="mb-6">
                                    <label className='items-start w-full text-gray-700 font-medium'>
                                        Address
                                        <input
                                            placeholder='Write your address with Street name'
                                            className="w-full rounded py-2 placeholder:text-md placeholder:font-normal placeholder:text-sm placeholder:text-gray-400 px-[14px] text-body-color text-base border border-[f0f0f0] outline-none focus-visible:shadow-none focus:border-primary items-start" type="text" name="address" value={formData.address} onChange={handleChange} required />
                                    </label>
                                </div>

                                <div class="mb-6">
                                    <label className='items-start w-full text-gray-700 font-medium'>
                                        City
                                        <input
                                            placeholder='Mention your City'
                                            className="w-full rounded py-2 placeholder:text-md placeholder:font-normal placeholder:text-sm placeholder:text-gray-400 px-[14px] text-body-color text-base border border-[f0f0f0] outline-none focus-visible:shadow-none focus:border-primary items-start" type="text" name="city" value={formData.city} onChange={handleChange} required />
                                    </label>
                                </div>

                                <div class="mb-6">
                                    <label className='items-start w-full text-gray-700 font-medium'>
                                        State
                                        <input
                                            placeholder='Mention your State'
                                            className="w-full rounded py-2 placeholder:text-md placeholder:font-normal placeholder:text-sm placeholder:text-gray-400 px-[14px] text-body-color text-base border border-[f0f0f0] outline-none focus-visible:shadow-none focus:border-primary items-start" type="text" name="state" value={formData.state} onChange={handleChange} required />
                                    </label>
                                </div>

                                <div className='mb-6'>
                                    <ReCAPTCHAComponent onChange={handleRecaptcha} />
                                </div>

                                <div className="mb-0">
                                    <button
                                        type="submit"
                                        disabled={!isCaptchaValid}
                                        className={`w-full bg-blue-600 rounded border border-primary p-3 transition ${!isCaptchaValid ? 'cursor-not-allowed opacity-50' : ''
                                            } hover:bg-blue-700 text-white`}
                                    >
                                      {!loading?  'Place Order':' Place Order... '} 
                                    </button>
                                </div>


                                {displayRazorpay && (
                                    <RenderRazorpay
                                        amount= {total}
                                        currency= "INR"
                                        orderId={ordeid}
                                        keyId={RAZOR_PAY_API_KEY}
                                        keySecret={RAZOR_PAY_API_SECRET}
                                        preFillFormData={preFillFormData}
                                        
                                    />
                                )
                                }

                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className='z-20'>
                {/* <ToastContainer /> */}
            </div>
        </section>
    );
};

export default Checkout;
