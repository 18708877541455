import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../config/config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from './AuthContext';
import backgroundImage from '../assets/images/admin-bg.jpg'
import ReCAPTCHAComponent from '../common/googleCaptcha';


function Login() {
    const { setLoginStatus } = useAuth();
    const { API_BASE_URL } = config;
    const navigate = useNavigate();
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [isCaptchaValid,setisCaptchaValid]=useState(false);

    const handleRecaptcha =(value)=>{
        setisCaptchaValid(!!value)

    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSignIn = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_BASE_URL}/auth/SignIn`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setLoginStatus(true);
                localStorage.setItem('isLoggedIn', JSON.stringify(true));
                navigate('/');
            } else {
                toast.error('Invalid email or password');
            }
        } catch (error) {
            console.error('Error signing in:', error);
            toast.error('An error occurred while signing in');
        }
    };

    return (
        <div>
            <section className="relative lg:mt-0 md:mt-0 xl:mt-0 mt-2"
            >
                <img
                    src={backgroundImage}
                    alt="Background"
                    className="absolute inset-0 w-full h-full object-cover brightness-75"
                />

                <div className="relative flex flex-col items-center justify-center px-6 py-3 mx-auto md:h-screen lg:py-0">
                    <div className="w-full bg-white rounded-lg shadow md:mt-0 mt-6 sm:max-w-md xl:p-0 p-2">
                        <div className="md:p-6 lg:p-6 p-2 space-y-4 md:space-y-6">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                                Sign-in to your account
                            </h1>
                            <form className="space-y-4 md:space-y-6" onSubmit={handleSignIn}>
                                <div>
                                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                                        placeholder="name@company.com"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                    <input
                                        type="password"
                                        name="password"
                                        id="password"
                                        placeholder="••••••••"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                        value={formData.password}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-start">
                                        <div className="flex items-center h-5">
                                            <input
                                                id="remember"
                                                aria-describedby="remember"
                                                type="checkbox"
                                                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300"

                                            />
                                        </div>
                                        <div className="ml-3 text-sm">
                                            <label htmlFor="remember" className="text-gray-500 ">Remember me</label>
                                        </div>
                                    </div>

                                </div>
                                <ReCAPTCHAComponent onChange={handleRecaptcha}/>
                                <button
                                    type="submit"
                                    className={`w-full text-black  hover:bg-primary-700 
                                    focus:ring-4 focus:outline-none focus:ring-primary-300 
                                    font-semibold rounded-lg text-md px-5 py-2.5 text-center ${isCaptchaValid ? 'bg-yellow-400 ': 'bg-yellow-300' }` }
                                    disabled={!isCaptchaValid}
                                >
                                    Sign in
                                </button>

                            </form>
                            <ToastContainer />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Login;
