/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo_3.png';
import { useAuth } from './AuthContext';

function Header() {
    const { isLoggedIn, setLoginStatus } = useAuth();

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const dropdownRef = useRef(null);
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };


    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const closeDropdown = () => {
        setDropdownOpen(false);
    };

    const handleOptionClick = (option) => {
        setIsMenuOpen(false);
        setDropdownOpen(false);
        setSelectedOption(option);
        closeDropdown();
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                closeDropdown();
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <header className='py-7'>
            <div className="bg-[#0f254e] fixed w-full z-20 top-0 start-0  items-center">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                    <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                        <img src={logo} className="h-12" alt="Hema-Aunty-Books-Of-Wishes" />
                    </Link>

                    <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse items-center">
                        <Link to={`/cart`}>

                            <button onClick={handleOptionClick} class="relative inline-flex items-center px-3 py-1 overflow-hidden text-lg font-medium text-black bg-white rounded-full hover:text-black group hover:bg-gray-50">
                                <span class="absolute left-0 block w-full h-0 transition-all bg-yellow-400 opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
                                <span class="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                                        />
                                    </svg>
                                </span>
                                <span class="">Cart</span>
                            </button>
                        </Link>


                        <button
                            onClick={toggleMenu}
                            type="button"
                            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-200 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
                            aria-controls="navbar-sticky"
                            aria-expanded={isMenuOpen ? "true" : "false"}
                        >
                            <span className="sr-only">Open main menu</span>
                            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                            </svg>
                        </button>
                    </div>

                    <div className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${isMenuOpen ? 'block' : 'hidden'}`} id="navbar-sticky">
                        <ul
                            ref={dropdownRef}
                            className="flex flex-col p-4 md:p-0 mt-4 font-medium rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:space-y-0 lg:space-y-0 xl:space-y-0 space-y-1">
                            <li onClick={() => handleOptionClick()}>
                                <Link to={`/`} className="block py-2 px-3 text-gray-700 bg-gray-100 rounded md:bg-transparent md:text-white md:p-0 font-light md:text-lg lg:text-lg xl:text-lg text-md" aria-current="page">Home </Link>
                            </li>
                            <li onClick={() => handleOptionClick()}>
                                <Link to={`/about`} className="block py-2 px-3 text-gray-700 bg-gray-100 rounded md:bg-transparent md:text-white md:p-0 font-light md:text-lg lg:text-lg xl:text-lg text-md">About</Link>
                            </li>
                            <li className="relative group" key="Books" ref={dropdownRef}>
                                <p
                                    className="py-2 px-3 text-gray-700 bg-gray-100 rounded md:bg-transparent md:text-white md:p-0 font-light md:text-lg lg:text-lg xl:text-lg text-md cursor-pointer flex items-center gap-1"
                                    onClick={toggleDropdown}
                                >
                                    Books
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                    </svg>
                                </p>

                                {isDropdownOpen && (
                                    <ul class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none p-4 space-y-2" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                                        <li onClick={handleOptionClick}>
                                            <Link to="/books/english" className="block text-gray-700 text-lg font-normal">
                                                English Books
                                            </Link>
                                        </li>
                                        <hr className='border-b border-gray-100' />
                                        <li onClick={handleOptionClick}>
                                            <Link to="/books/hindi" className="block text-gray-700 text-lg font-normal">
                                                Hindi Books
                                            </Link>
                                        </li>
                                        <hr className='border-b border-gray-100' />
                                        <li onClick={handleOptionClick}>
                                            <Link to="/books/special-books-product-gift" className="block text-gray-700 text-lg font-normal">
                                                Special Books and products for Gifting
                                            </Link>
                                        </li>
                                        <hr className='border-b border-gray-100' />

                                        <li onClick={handleOptionClick}>
                                            <Link to="/books/video" className="block text-gray-700 text-lg font-normal">
                                                Audio/Video Books
                                            </Link>
                                        </li>

                                    </ul>
                                )}
                            </li>
                            <li onClick={() => handleOptionClick()}>
                                <Link to={`blog`} className="block py-2 px-3 text-gray-700 bg-gray-100 rounded md:bg-transparent md:text-white md:p-0 font-light md:text-lg lg:text-lg xl:text-lg text-md">Blog</Link>
                            </li>
                            <li onClick={() => handleOptionClick()}>
                                <Link to={`/contact`} className="block py-2 px-3 text-gray-700 bg-gray-100 rounded md:bg-transparent md:text-white md:p-0 font-light md:text-lg lg:text-lg xl:text-lg text-md">Contact</Link>
                            </li>
                            <li onClick={() => handleOptionClick()}>
                                <Link to={`/track-order`} className="block py-2 px-3 text-gray-700 bg-gray-100 rounded md:bg-transparent md:text-white md:p-0 font-light md:text-lg lg:text-lg xl:text-lg text-md">Track-Order</Link>
                            </li>
                            {isLoggedIn ? (
                                <li onClick={() => handleOptionClick()}>
                                    <Link to={`/profile-dashboard`} className="block py-2 px-3 text-gray-700 bg-gray-100 rounded md:bg-transparent md:text-white md:p-0 font-light md:text-lg lg:text-lg xl:text-lg text-md">View Profile</Link>
                                </li>
                            ) : (
                                <li onClick={() => handleOptionClick()}>
                                    <Link to={`/login`} className="block py-2 px-3 text-gray-700 bg-gray-100 rounded md:bg-transparent md:text-white md:p-0 font-light md:text-lg lg:text-lg xl:text-lg text-md">Admin</Link>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
