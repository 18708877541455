import React from 'react'
import English from '../components/English'
import EnglishHero from '../components/EnglishHero'

function EnglishPage() {
    return (
        <div>
            <EnglishHero />
            <English />
        </div>
    )
}

export default EnglishPage