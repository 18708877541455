import React from 'react'
import Hero from '../components/Hero'
import BookList from '../components/BookList'
import Teams from '../components/Teams'
import Logo from '../components/Logo'
import FeaturedBooks from '../components/FeaturedBooks'

const Home = () => {
    return (
        <section>
            <Hero />
            <FeaturedBooks />
            <BookList />
            <Teams />
            <Logo />
        </section>
    )
}

export default Home