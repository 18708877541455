import React from 'react'

const Return = () => {
  return (
     <>
     <section className="mb-6 w-full h-screen mt-6 p-10 font-sans text-justify bg-slate-100 ">
      <h2 className="text-2xl font-semibold mb-2 -mt-4">CANCELLATION AND REFUND
      </h2>
      <p className="text-gray-700">
        In case of faulty supplies, our policy lasts 30 days. To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.
      </p>
      <p className="text-gray-700">
        To complete your refund, we require a receipt or proof of purchase. Once your return is received and inspected, we will <strong>send you an email to notify you that we have received your returned item.</strong> We will also notify you of the approval or rejection of your refund. If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days.
      </p>
      <p className="text-gray-700">
        If 30 days have gone by since your purchase, we unfortunately can’t offer you a refund.
      </p>
      <section className="mb-6 mt-4" id={'shipping'}>
        <h2 className="text-2xl font-semibold mb-2">Exchanges</h2>
        <p className="text-gray-700">We do not offer exchanges or replacements.</p>
      </section>
    </section>
      </>

  )
}

export default Return