import React from 'react'
import Header from '../../Header'

function TermsConditions() {
    return (
        <div>
            <Header />
            <div>
            
                <div className="bg-white text-gray-900 p-6 md:p-12 lg:p-20 -mt-16 font-sans text-justify bg-slate-100 ">
                    <h1 className="text-3xl font-bold mb-6 text-center -mt-6 underline">TERMS AND CONDITIONS
                    </h1>

                    <section className="mb-6 -mt-8">
                        <h2 className="text-2xl font-semibold mb-2">In short:</h2>
                        <p className="text-gray-700">
                            We are Hema Aunty Foundation <strong>(Hem Aunty Publications, an imprint of Samskar’)’</strong> and we provide educational resources and support for children.
                        </p>
                    </section>

                    <section className="mb-6 -mt-4">
                        <h2 className="text-2xl font-semibold mb-2">Legal Information</h2>
                        <p className="text-gray-700">
                            This document is a written binding agreement and an electronic record under the Information <strong>Technology Act, 2000, </strong>and rules thereunder as applicable and the amended provisions pertaining to electronic records in various statutes under applicable Indian laws. This electronic record is generated by a computer system and does not require any physical or digital signatures.
                        </p>
                    </section>

                    <section className="mb-6 -mt-3">
                        <h2 className="text-2xl font-semibold mb-2">Introduction</h2>
                        <p className="text-gray-700">
                            This website is operated by <strong>(Hem Aunty Publications, an imprint of ‘Samskar’).</strong> These Terms and Conditions (the “Terms and Conditions”) are a binding written contract between you and the Hema Aunty Foundation (“Hema Aunty Foundation”) (Samskar), a nonprofit organization (Society) incorporated under the laws of India, with its<strong> registered office at 18 Chitra Vihar, Delhi.</strong>
                        </p>
                        <p className="text-gray-700">
                            To avoid any possibility of confusion, when you see references to “we” or “us” or “our”, all of those references are to the<strong> Hema Aunty Foundation (Samskar) (collectively with its agents, consultants, employees, officers, and Trustees);</strong> when you see a reference to “you” or “your”, we are referring to you, the User of one of our websites or services.
                        </p>
                        <p className="text-gray-700">
                            By visiting our site (“Website”/ “Site) and / or availing of the services offered here or through its affiliates, you agree to be bound by the following Terms and Conditions. Please ensure that you have read these Terms and Conditions carefully before accessing our website.
                        </p>
                    </section>

                    <section className="mb-6 -mt-3">
                        <h2 className="text-2xl font-semibold mb-2">Use of the Website by Children</h2>
                        <p className="text-gray-700">
                            You represent and warrant that you are of legal age to form a binding contract (and if not, you have received your parent’s or guardian’s permission to use the services and they have agreed to these Terms on your behalf, as described earlier). If you are agreeing to these terms on behalf of an organization or entity (for example, if you are an administrator agreeing to these Terms on behalf of your school or district), you represent and warrant that you are authorized to agree to these terms on that organization or entity’s behalf and bind them to these terms.
                        </p>
                        <p className="text-gray-700">
                            We encourage parents and teachers to spend time online with children and to appropriately monitor their online activities. Please protect your child’s privacy by instructing them to never provide personal information on this site or any other, or within any software registration process without your permission.
                        </p>
                    </section>

                    <section className="mb-6 -mt-3">
                        <h2 className="text-2xl font-semibold mb-2">How We Reach Out to You</h2>
                        <p className="text-gray-700">
                            When you use the services on our website or send emails or other data, information, or communication to us, you agree and understand that you are communicating with us and the website and all other users and visitors through electronic records which are legally identifiable and enforceable, and you agree to receive communications via electronic records from us, the website and all other users and visitors and as and when posted, communicated or required.
                        </p>
                    </section>

                    <section className="mb-6 -mt-3">
                        <h2 className="text-2xl font-semibold mb-2">Products and Services</h2>
                        <p className="text-gray-700">The products and services available on our site are as follows:</p>
                        <ul className="list-disc list-inside ml-4 text-gray-700">
                            <li><strong>e-Store:</strong> A digital storefront for Hema Aunty Foundation <strong>(Hem Aunty Publications) </strong>where people in India can buy educational resources, storybooks, and learning kits created and published by the Hema Aunty Foundation(Hem Aunty Publications).</li>
                            <li><strong>Downloads:</strong> At present, users can download the Hema Aunty Foundation<strong> (Hem Aunty Publications)</strong> catalogue, Annual Reports, Individual, Corporate, and Foreign Donors’ lists.</li>
                            <li><strong>Blog / Newsletter Subscription:</strong> Users can subscribe to our newsletter and our blog for updates on our events, new book launches, workshops, and other activities.</li>
                        </ul>
                    </section>

                    <section className="mb-6 -mt-3">
                        <h2 className="text-2xl font-semibold mb-2" id={'return'}>Personal Information</h2>
                        <p className="text-gray-700">
                            Your submission of any personal information through the website or e-store is governed by our Privacy Policy.
                        </p>
                    </section>

                  

                   
                  

                    <section className="mb-6 -mt-3">
                        <h2 className="text-2xl font-semibold mb-2">Content</h2>
                        <p className="text-gray-700">
                            Users have a personal, non-transferable right to access and use the content of this site subject to these terms and conditions. The term<strong> “Content” includes, without limitation, any information, data, text, photographs, videos, audio clips, written posts and comments, software, scripts, graphics, and interactive features generated, provided,</strong>or otherwise made accessible on or through the services.
                        </p>
                        <p className="text-gray-700">
                            You may not use the site or its content for any commercial purpose, including advertising or solicitation, without our prior written consent.
                        </p>
                    </section>

                    <section className="mb-6 -mt-3">
                        <h2 className="text-2xl font-semibold mb-2">Disclaimer of Warranties; Limitation of Liability</h2>
                        <p className="text-gray-700">
                            We do not guarantee, represent, or warrant that your use of our service will be uninterrupted, timely, secure, or error-free.
                        </p>
                        <p className="text-gray-700">
                            We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.
                        </p>
                        <p className="text-gray-700">
                            You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.
                        </p>
                        <p className="text-gray-700">
                            You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties, or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.
                        </p>
                    </section>

                    <section className="mb-6 -mt-3">
                        <h2 className="text-2xl font-semibold mb-2">Indemnification</h2>
                        <p className="text-gray-700">
                            You agree to indemnify, defend and hold harmless Hema Aunty Foundation and our <strong>parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns, and employees, harmless from any claim or demand, including reasonable attorneys’ fees, </strong>made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.
                        </p>
                    </section>

                    <section className="mb-6 -mt-3">
                        <h2 className="text-2xl font-semibold mb-2">Termination</h2>
                        <p className="text-gray-700">
                            The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.
                        </p>
                        <p className="text-gray-700">
                            These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.
                        </p>
                        <p className="text-gray-700">
                            If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).
                        </p>
                    </section>

                    <section className="mb-6 -mt-3">
                        <h2 className="text-2xl font-semibold mb-2">Governing Law</h2>
                        <p className="text-gray-700">
                            These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of India.
                        </p>
                    </section>

                    <section className="mb-6 -mt-3">
                        <h2 className="text-2xl font-semibold mb-2">Changes to Terms of Service</h2>
                        <p className="text-gray-700">
                            You can review the most current version of the Terms of Service at any time at this page.
                        </p>
                        <p className="text-gray-700">
                            We reserve the right, at our sole discretion, to update, change, or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.
                        </p>
                    </section>

                    <section className="mb-6 -mt-3">
                        <h2 className="text-2xl font-semibold mb-2">Contact Information</h2>
                        <p className="text-gray-700">
                            Questions about the Terms of Service should be sent to us at <strong>contact@hemaunty.org.</strong>
                        </p>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default TermsConditions