import React, { useEffect, useState } from 'react';
import $ from 'jquery';

const FlipBook = (pdfUrl) => {
 // console.log("pdf",pdfUrl)
  // const [Pdflink, setPdflink] = useState(pdfUrl);
  // const [Pdflink, setPdflink] = useState(sessionStorage.getItem('pdflink'));
  useEffect(() => {
    // Dynamically load the DFlip script
    const dflipScript = document.createElement('script');
    dflipScript.src = `${process.env.PUBLIC_URL}/dflip/js/dflip.min.js`;
    dflipScript.onload = () => {
      // Initialize the flipbook after the script is loaded
      console.log("DFlip script loaded successfully");
      if (typeof $.fn.flipBook === 'function') {
        $('#df_manual_book').each(function () {
          $(this).flipBook({
            // options
          });
        });
      } else {
        console.error("flipBook function is not available.");
      }
    };
    dflipScript.onerror = () => {
      console.error("Failed to load DFlip script");
    };
    document.body.appendChild(dflipScript);

    // Dynamically load DFlip CSS files
    const dflipCSS = document.createElement('link');
    dflipCSS.rel = 'stylesheet';
    dflipCSS.href = `${process.env.PUBLIC_URL}/dflip/css/dflip.min.css`;
    document.head.appendChild(dflipCSS);

    const themifyIconsCSS = document.createElement('link');
    themifyIconsCSS.rel = 'stylesheet';
    themifyIconsCSS.href = `${process.env.PUBLIC_URL}/dflip/css/themify-icons.min.css`;
    document.head.appendChild(themifyIconsCSS);

    return () => {
      // Clean up: Remove the dynamically loaded scripts and stylesheets
      document.body.removeChild(dflipScript);
      document.head.removeChild(dflipCSS);
      document.head.removeChild(themifyIconsCSS);
    };
  }, []);

  return (
    <div className=" flex items-center justify-center mx-4">
      <div className="w-full">
        <div className="mx-auto">
          <div
            className="_df_book"
            height="500"
            webgl="true"
            source={pdfUrl.pdfUrl}
            id="df_manual_book"
          ></div>
          {/* <div className='mx-auto justify-center max-w-fit mt-6'>
            <button className='px-4 py-2 bg-yellow-400 text-gray-700 font-medium tracking-[1px] rounded-md'>Back to books</button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default FlipBook;
