import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import FlipBook from './flipBook';


const PdfModal = ({ value, pdf_link,onClose  }) => {
  console.log("pdfmodal",pdf_link)
  console.log("value",value)

  const [isOpen, setIsOpen] = useState(value);
  return (
    <div className=" bg-slate-900 grid place-content-center">
      {/* <button
        onClick={() => setIsOpen(true)}
        className="bg-gradient-to-r from-violet-600 to-indigo-600 text-white font-medium px-4 py-2 rounded hover:opacity-90 transition-opacity"
      >
        Open Modal
      </button> */}
      <SpringModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose} pdf_url={pdf_link} />
    </div>
  );
};

const SpringModal = ({ isOpen, setIsOpen ,onClose,pdf_url}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onClose}
          className="bg-slate-900/20 backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer"
        >
         
          <motion.div
            initial={{ scale: 0, rotate: "12.5deg" }}
            animate={{ scale: 1, rotate: "0deg" }}
            exit={{ scale: 0, rotate: "0deg" }}
            onClick={(e) => e.stopPropagation()}
            className=" text-white  bg-[#752639] rounded-lg w-full max-w-screen-lg shadow-xl cursor-default relative  overflow-hidden"
          >
             <div className="flex justify-end">
           
            <button
             onClick={onClose}
              className="   hover:opacity-90 transition-opacity text-indigo-600 font-semibold   "
            >
              <svg class="h-8 w-8 text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </button>
          </div>
            <div className="relative z-10">
             
              <p className="text-center mb-1 mt-1 -mx-3 -my-3">
                <FlipBook pdfUrl={pdf_url} />

              </p>

            </div>

          </motion.div>

        </motion.div>

      )}
    </AnimatePresence>
  );
};

export default PdfModal;