import React from 'react'
import Hero from '../components/Hero'
import AboutMain from '../components/AboutMain'

function AboutPage() {
    return (
        <div>
            <Hero />
            <AboutMain />
        </div>
    )
}

export default AboutPage