import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import DASHBOARD_USER_PROFILE from './DASHBOARD_USER_PROFILE';
import DASHBOARD_ORDERS from './DASHBOARD_ORDERS';

function Dashboard() {

    const [activeTab, setActiveTab] = useState('profile');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const { setLoginStatus } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        setLoginStatus(false);
        localStorage.removeItem('isLoggedIn');
        navigate('/');
    };

    return (
        <div className='mt-10'>
            <div class="w-full bg-white shadow-xl rounded-lg flex overflow-x-auto custom-scrollbar">
                <div class="md:w-64 lg:w-64 xl:w-64 2xl:w-64 w-36 md:px-4 lg:px-4 xl:px-4 px-0">
                    <div class="md:px-2 lg:px-2 xl:px-2 2xl:px-2 px-0 pt-4 pb-8 border-r border-gray-300">
                        <ul class="space-y-2">
                            <li>
                                <button
                                    onClick={() => handleTabChange('profile')}
                                    className={`hover:bg-gray-500 hover:bg-opacity-10 hover:text-blue-600 flex items-center text-gray-700 py-1.5 px-2 rounded space-x-2 cursor-pointer w-full ${activeTab === 'profile' ? 'bg-gray-500 bg-opacity-10 text-blue-600' : ''
                                        }`}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                                    </svg>

                                    <p>Profile</p>

                                </button>
                            </li>

                            <li>
                                <button
                                    onClick={() => handleTabChange('orders')}
                                    className={`hover:bg-gray-500 hover:bg-opacity-10 hover:text-blue-600 flex items-center text-gray-700 py-1.5 px-2 rounded space-x-2 cursor-pointer w-full ${activeTab === 'orders' ? 'bg-gray-500 bg-opacity-10 text-blue-600' : ''
                                        }`}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
                                    </svg>

                                    <p>Orders</p>
                                </button>
                            </li>


                            <li>

                                <button onClick={handleLogout} class="hover:bg-gray-500 hover:bg-opacity-10 hover:text-blue-600 flex items-center text-gray-700 py-1.5 px-2 rounded space-x-2 cursor-pointer w-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                                    </svg>
                                    <span>Logout</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="flex-1 px-2 max-w-6xl">
                    {activeTab === 'profile' && <DASHBOARD_USER_PROFILE />}
                    {activeTab === 'orders' && <DASHBOARD_ORDERS />}
                </div>
            </div>
        </div>
    )
}

export default Dashboard

