import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHAComponent from '../common/googleCaptcha';

function ContactForm() {
    const [email, setEmail] = useState('');
    const subject = 'HemAunty - Book Enquiry';
    const [body, setBody] = useState('');
    const [file, setFile] = useState(null);
    const [isCaptchaValid,setisCaptchaValid]=useState(false);

    const handleRecaptcha =(value)=>{
        setisCaptchaValid(!!value)

    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('ToEmail', email);
        formData.append('Subject', subject);
        formData.append('Body', body);
        if (file) {
            formData.append('Attachments', file);
        }

        try {
            const response = await fetch('https://edmcorpservices.edumitram.co.in/api/Email/SendHemPub', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to send data');
            }

            toast.success('Your issue will be addressed soon. Kindly Wait!');

            setEmail('');
            setBody('');
            setFile(null);
        } catch (error) {
            toast.error('Failed to send data');
        }
    };

    return (
        <section className="bg-white">
            <ToastContainer />
            <h1 className="mt-2 text-2xl font-semibold text-gray-800 md:text-3xl max-w-7xl md:px-6 lg:px-6 px-2 mx-auto">Fill out the form and our team will contact you in 1-2 working days.</h1>
            <div className="py-8 lg:py-16 px-8 mx-auto max-w-7xl">
                <form onSubmit={handleSubmit} className="space-y-8 max-w-4xl">
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900"> Email:
                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5" placeholder="Email" required />
                        </label>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900">Your message
                            <textarea value={body} onChange={(e) => setBody(e.target.value)} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300" rows="6" placeholder="Message" required></textarea>
                        </label>
                    </div>
                    <ReCAPTCHAComponent onChange={handleRecaptcha}/>
                    
                    <button type="submit" className={`py-3 px-5 text-sm font-medium text-center
                     text-white rounded-lg bg-primary-700 sm:w-fit  focus:ring-4
                     ${isCaptchaValid ? 'bg-blue-600  hover:bg-blue-600':' bg-blue-400 '}`}
                    disabled={!isCaptchaValid}
                    >Send message</button>
                </form>
            </div>
        </section>
    )
}

export default ContactForm;
