import React from 'react';
import author1 from "../assets/images/author1 (1).jpeg";
import author2 from "../assets/images/self 2.jpg";
import author3 from "../assets/images/garav bhatnagar2.jpg";

function Teams() {
    return (
        <div>
            <section class="bg-white">
                <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 ">

                    <div class="mx-auto max-w-7xl mb-8 lg:mb-16">
                        <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">Our Core Team</h2>
                        <hr className='border-b-2 border-gray-200' />
                    </div>


                    <div class="grid gap-8 mb-6 lg:mb-16 md:grid-cols-2">
                        <div class="items-center bg-gray-50 rounded-lg shadow sm:flex  ">
                            <p>
                                <img class="md:w-[300px] lg:w-[300px] w-full rounded-lg sm:rounded-full sm:rounded-l-lg" src={author1} alt="Amit Bhatnagar - Team" />
                            </p>
                            <div class="p-5">
                                <h3 class="text-xl font-bold tracking-tight text-gray-900 ">
                                    <p >Amit Bhatnagar</p>
                                </h3>
                                <span class="text-gray-500  underline underline-offset-2">Educationist and Author</span>
                            </div>
                        </div>

                        <div class="items-center bg-gray-50 rounded-lg shadow sm:flex  ">
                            <p>
                                <img class="md:w-[300px] lg:w-[350px] w-full rounded-lg sm:rounded-full sm:rounded-l-lg" src={author2} alt="Amit Bhatnagar - Team" />
                            </p>
                            <div class="p-5">
                                <h3 class="text-xl font-bold tracking-tight text-gray-900 ">
                                    <p >Madhulika Agarwal</p>
                                </h3>
                                <span class="text-gray-500  underline underline-offset-2">Author</span>
                            </div>
                        </div>



                        <div class="items-center bg-gray-50 rounded-lg shadow sm:flex  ">
                            <p>
                                <img class="md:w-[300px] lg:w-[300px] w-full rounded-lg sm:rounded-full sm:rounded-l-lg" src={author3} alt="Gaurav Bhatnagar - Team" />
                            </p>
                            <div class="p-5">
                                <h3 class="text-xl font-bold tracking-tight text-gray-900 ">
                                    <p >Gaurav Bhatnagar</p>
                                </h3>
                                <span class="text-gray-500  underline underline-offset-2">Mathematician</span>
                                {/*  <p class="mt-3 mb-4 font-light text-gray-500 ">Gaurav Bhatnagar obtained his Ph.D. in Mathematics from The Ohio State University in 1995.</p>*/}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Teams