import React from 'react'
function ContactInfo() {
    return (
        <div id='contact'>
            <section class=" max-w-7xl mx-auto">
                <div class="container px-6 py-12 mx-auto">
                    <div>
                        <p class="font-medium text-blue-500 ">Contact us</p>

                        <h1 class="mt-2 text-2xl font-semibold text-gray-800 md:text-3xl ">Get in touch</h1>

                        <p class="mt-3 text-gray-500 italic">Our friendly team is always here to chat.</p>
                    </div>

                    <div class="grid grid-cols-1 gap-12 mt-10 md:grid-cols-2 lg:grid-cols-3">
                        <div className='bg-gray-100/90 md:p-4 lg:p-4 xl:p-4 p-2 rounded-md'>
                            <span class="inline-block p-3 text-yellow-500 rounded-full bg-yellow-100/80 ">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                    stroke="currentColor" class="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                </svg>
                            </span>

                            <h2 class="mt-4 text-lg font-medium text-gray-800 ">Email</h2>
                            <p class="mt-2 text-gray-500 ">Our friendly team is here to help.</p>
                            <p class="mt-2 text-blue-500 font-semibold ">support@hemaunty.org</p>
                            <p class="mt-2 text-blue-500 font-semibold ">madhulika55.agarwal@gmail.com</p>
                        </div>

                        <div className='bg-gray-100/90 md:p-4 lg:p-4 xl:p-4 p-2 rounded-md'>
                            <span class="inline-block p-3 text-yellow-500 rounded-full bg-yellow-100/80 ">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                                </svg>
                            </span>

                            <h2 class="mt-4 text-lg font-medium text-gray-800 ">Phone Number</h2>
                            <p class="mt-2 text-gray-500 ">You can also contact us on this number.</p>
                            <p class="mt-2 text-blue-500 font-semibold ">+91 9958410093</p>
                        </div>
                        <div className='bg-gray-100/90 md:p-4 lg:p-4 xl:p-4 p-2 rounded-md'>
                            <span class="inline-block p-3  text-yellow-500 rounded-full bg-yellow-100/80">

                            <svg viewBox="0 0 1024 1024" fill="#ffd23d" class="icon w-6 h-6" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#ffd23d"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M512 1012.8c-253.6 0-511.2-54.4-511.2-158.4 0-92.8 198.4-131.2 283.2-143.2h3.2c12 0 22.4 8.8 24 20.8 0.8 6.4-0.8 12.8-4.8 17.6-4 4.8-9.6 8.8-16 9.6-176.8 25.6-242.4 72-242.4 96 0 44.8 180.8 110.4 463.2 110.4s463.2-65.6 463.2-110.4c0-24-66.4-70.4-244.8-96-6.4-0.8-12-4-16-9.6-4-4.8-5.6-11.2-4.8-17.6 1.6-12 12-20.8 24-20.8h3.2c85.6 12 285.6 50.4 285.6 143.2 0.8 103.2-256 158.4-509.6 158.4z m-16.8-169.6c-12-11.2-288.8-272.8-288.8-529.6 0-168 136.8-304.8 304.8-304.8S816 145.6 816 313.6c0 249.6-276.8 517.6-288.8 528.8l-16 16-16-15.2zM512 56.8c-141.6 0-256.8 115.2-256.8 256.8 0 200.8 196 416 256.8 477.6 61.6-63.2 257.6-282.4 257.6-477.6C768.8 172.8 653.6 56.8 512 56.8z m0 392.8c-80 0-144.8-64.8-144.8-144.8S432 160 512 160c80 0 144.8 64.8 144.8 144.8 0 80-64.8 144.8-144.8 144.8zM512 208c-53.6 0-96.8 43.2-96.8 96.8S458.4 401.6 512 401.6c53.6 0 96.8-43.2 96.8-96.8S564.8 208 512 208z" fill=""></path></g></svg>                            </span>

                            <h2 class="mt-2 text-lg font-medium text-gray-800 ">Address</h2>
                            <p class="mt-2 text-gray-500 ">You can reach us this address .</p>
                            <p class="mt-2 text-blue-500 font-semibold ">18 Chitra Vihar , Delhi 110092</p>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default ContactInfo