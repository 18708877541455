import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';

const ReCAPTCHAComponent = ({ onChange }) => {
  const recaptchaRef = useRef(null);

  return (
    <ReCAPTCHA
      ref={recaptchaRef}
      sitekey='6LfeZfApAAAAAI5YNyhGzHti5qZ-AIF3TwN5LyHe'
      onChange={onChange}
    />
  );
};

ReCAPTCHAComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default ReCAPTCHAComponent;
