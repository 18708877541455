import React,{useState} from 'react';
import { bookdata } from "../data";
import videoHem from "../assets/video/hemauntyvideoplay.mp4"
import { useNavigate } from 'react-router-dom';
import PdfModal from '../common/PdfModal';




function Video() {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');


    const audioVideoBooks = bookdata.filter((book) => book.booktype === 'AudioVideo');
    const handleViewPdfClick = (bookpdflink) => {
        setPdfUrl(bookpdflink);
        setShowModal(true);
       

       // sessionStorage.setItem('pdflink', bookpdflink)
        if (bookpdflink.includes('drive.google.com')) {

            //console.log("Pdflink", bookpdflink);
            //console.log(bookpdflink.includes('drive.google.com'));
            return window.open(bookpdflink, '_blank');

        }
        else {
            //navigate('/books/flip');


        }

        // Show the ad
        // setShowAd(true);
        // // Hide the ad after 10 seconds
        // setTimeout(() => {
        //     setShowAd(false);

        //     // navigate('/books/flip');
        //     // Redirect to PDF link
        //     // window.open(bookData.pdflink, '_blank');
        // }, 10000);
    };
    const handleCloseModal = () =>{
        setShowModal(false);
    }


    return (
        <div className="h-auto mt-16 mb-24 px-2">
            <div className='max-w-7xl mx-auto py-5'><p className='text-lg font-semibold bg-yellow-400 py-2 px-5 w-fit mb-2 rounded-full'><h2>Video Book</h2></p> <hr className='border-b-2 border-gray-200' /></div>
            <div className="flex relative overflow-hidden flex-col text-left md:flex-row max-w-full md:px-16 lg:px-16 justify-center xl:px-20 px-[2px] mx-auto md:mt-5 mt-10 items-center z-0">

                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 md:px-5 lg:px-5 xl:px-5 px-4 gap-8 md:space-y-0 lg:space-y-0 xl:space-y-0 space-y-2 pb-2 md:gap-y-8 lg:gap-y-8 xl:gap-y-8 gap-y-5">
                    <div className="justify-center">
                        {audioVideoBooks.map((book) => (
                            <div key={book.id} className="rounded-lg shadow-lg bg-white max-w-sm">
                                <div href="/">
                                    {book.booktype === 'AudioVideo' ? (
                                        <video className="md:max-w-4xl lg:max-w-4xl xl:max-w-4xl w-full h-full 2xl:max-w-4xl mx-auto p-2 justify-between rounded-lg" controls>
                                            <source src={videoHem} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    ) : null}
                                </div>
                                <div className="p-6">
                                    <h5 className="text-gray-900 text-xl font-medium mb-2">{book.booknameinenglish}</h5>
                                    <p className="text-gray-700 text-base mb-4">{book.description}</p>
                                    <button

                                        onClick={() => handleViewPdfClick(book.pdflink)}
                                        type="button"
                                        className="inline-block px-6 py-2.5 bg-yellow-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-yellow-500 hover:shadow-lg focus:bg-yellow-500 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                                    >
                                        <p rel="noopener noreferrer">View Book</p>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {showModal &&  pdfUrl &&  <PdfModal value={showModal} pdf_link={pdfUrl}  onClose={handleCloseModal}/>}

        </div>
    )
}

export default Video